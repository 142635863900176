import Head from 'next/head';
import React from 'react';

type MetaProps = {
  title: string;
  description: string;
  imageUrl?: string;
  timestamp?: string;
};

export function Meta({ title, description, timestamp }: MetaProps) {
  return (
    <Head>
      <title>YLDR - {title}</title>
      <meta name="description" content={description} key="description" />
      <meta property="og:title" content={`YLDR - ${title}`} key="title" />
      <meta property="og:description" content={description} key="ogdescription" />
      {timestamp && <meta name="revised" content={timestamp} key="timestamp" />}
      <meta
        name="keywords"
        key="keywords"
        content="Decentralized Finance, DeFi, lending, borrowing, stablecoins, Ethereum, assets, erc-20, smart contracts, open finance, trustless"
      />
      <meta name="apple-mobile-web-app-status-bar-style" content="#0C0C0D" />
      <meta name="theme-color" content="#0C0C0D" />
    </Head>
  );
}
