import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../ui-config/queries';
import { useProtocolDataContext } from '../useProtocolDataContext';
import { EMarketName } from '../../ui-config/marketsConfig';

export interface IMarketDataResponse {
  id: number;
  name: EMarketName;
  chain_id: number;
  create_and_leverage_address: string;
  data_provider_address: string;
  deposit_zap_address: string;
  leverage_address: string;
  leverage_data_provider_address: string;
  position_manager_address: string;
  position_wrapper_address: string;
  leverage_automations_address: string;
}

export interface IMarketData {
  chainId: number;
  marketId: number;
  marketName: EMarketName;
  addresses: {
    createAndLeverageAddress: string;
    dataProviderAddress: string;
    depositZapAddress: string;
    leverageAddress: string;
    leverageDataProviderAddress: string;
    positionManagerAddress: string;
    positionWrapperAddress: string;
    leverageAutomationsAddress: string;
  }
}

const INITIAL_DATA: IMarketData[] = [];

export const getMarkets = async (endpointURL: string) => {
  try {
    const url = `${endpointURL}/concentrated_liquidity/markets`;
    const result = await fetch(url);
    const json: IMarketDataResponse[] = await result.json();
    return json;
  } catch {
    return [];
  }
};

export const useMarketsQuery = () => {
  const { currentNetworkConfig } = useProtocolDataContext();
  const apiUrl = currentNetworkConfig.yldrYpiUrl;

  const { data, ...rest } = useQuery({
    queryFn: () => getMarkets(apiUrl),
    queryKey: [QueryKeys.MARKETS, apiUrl],
    select: (response): IMarketData[] => response.map((item) => ({
      chainId: item.chain_id,
      marketId: item.id,
      marketName: item.name,
      addresses: {
        createAndLeverageAddress: item.create_and_leverage_address,
        dataProviderAddress: item.data_provider_address,
        depositZapAddress: item.deposit_zap_address,
        leverageAddress: item.leverage_address,
        leverageDataProviderAddress: item.leverage_data_provider_address,
        positionManagerAddress: item.position_manager_address,
        positionWrapperAddress: item.position_wrapper_address,
        leverageAutomationsAddress: item.leverage_automations_address,
      }
    })),
    refetchOnMount: false,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  };
};
