import { Field } from '../LiquidityChartRangeInput/types';
import { Currency, CurrencyAmount } from '@uniswap/sdk-core';

export enum ECreationStep {
  Create = 'create',
  Leverage = 'leverage',
}

export enum EStrategyMode {
  Manual = 'manual',
  Automatic = 'Automatic',
}

export interface IStrategyModeItem {
  value: EStrategyMode;
  label: string;
}

export type TMaxAmount = { [field in Field]?: CurrencyAmount<Currency> };
