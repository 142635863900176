import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { multicall } from '../hooks/useMultipleContractData';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import mintV3 from './mint/v3/reducer';
import application from './application/reducer';

const rootReducer = combineReducers({
  application,
  mintV3,
  multicall: multicall.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
