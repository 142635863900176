import { ChainId } from '@uniswap/sdk-core';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import useIsWindowVisible from './useIsWindowVisible';

const MISSING_PROVIDER = Symbol()

const BlockNumberContext = createContext<
  | {
  fastForward(block: number): void
  block?: number
  mainnetBlock?: number
}
  | typeof MISSING_PROVIDER
  >(MISSING_PROVIDER)

function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext)
  if (blockNumber === MISSING_PROVIDER) {
    throw new Error('BlockNumber hooks must be wrapped in a <BlockNumberProvider>')
  }
  return blockNumber
}

export function useMainnetBlockNumber(): number | undefined {
  return useBlockNumberContext().mainnetBlock
}

/** Requires that BlockUpdater be installed in the DOM tree. */
export const useBlockNumber = (): number | undefined => {
  return useBlockNumberContext().block
};

export function BlockNumberProvider({ children }: { children: ReactNode }) {
  const { chainId: activeChainId, provider } = useWeb3Context();
  const [{ chainId, block, mainnetBlock }, setChainBlock] = useState<{
    chainId?: number
    block?: number
    mainnetBlock?: number
  }>({});

  const activeBlock = chainId === activeChainId ? block : undefined;

  const onChainBlock = useCallback((chainId: number, block: number) => {
    setChainBlock((chainBlock) => {
      if (chainBlock.chainId === chainId) {
        if (!chainBlock.block || chainBlock.block < block) {
          const mainnetBlock = chainId === ChainId.MAINNET ? block : chainBlock.mainnetBlock
          return { chainId, block, mainnetBlock }
        }
      } else if (chainId === ChainId.MAINNET) {
        if (!chainBlock.mainnetBlock || chainBlock.mainnetBlock < block) {
          return { ...chainBlock, mainnetBlock: block }
        }
      }
      return chainBlock
    })
  }, []);

  const windowVisible = useIsWindowVisible();

  useEffect(() => {
    if (provider && activeChainId && windowVisible) {
      setChainBlock((chainBlock) => {
        if (chainBlock.chainId !== activeChainId) {
          return { chainId: activeChainId, mainnetBlock: chainBlock.mainnetBlock }
        }
        // If chainId hasn't changed, don't invalidate the reference, as it will trigger re-fetching of still-valid data.
        return chainBlock
      })

      const onBlock = (block: number) => onChainBlock(activeChainId, block)
      provider.on('block', onBlock)
      return () => {
        provider.removeListener('block', onBlock)
      }
    }
    return
  }, [activeChainId, provider, windowVisible, onChainBlock])

  useEffect(() => {
    if (provider) {
      provider.getBlockNumber()
        .then((block: any) => {
          onChainBlock(ChainId.MAINNET, block)
        })
    }
  }, [provider, onChainBlock])

  const value = useMemo(
    () => ({
      fastForward: (update: number) => {
        if (activeBlock && update > activeBlock) {
          const mainnetUpdate = activeChainId === ChainId.MAINNET ? update : mainnetBlock;
          setChainBlock({ chainId: activeChainId, block: update, mainnetBlock: mainnetUpdate });
        }
      },
      block: activeBlock,
      mainnetBlock,
    }),
    [activeBlock, activeChainId, mainnetBlock]
  );
  return <BlockNumberContext.Provider value={value}>{children}</BlockNumberContext.Provider>;
}
