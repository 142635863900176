import { Provider } from '@ethersproject/providers';
import {
  tEthereumAddress,
  YLDRLeverageAutomations,
  ScheduledRebalanceStructOutput,
  ScheduledDeleverageStructOutput,
  ScheduledCompoundStructOutput,
} from '@yldr/contract-helpers';

export class YLDRLeverageAutomationsService {
  constructor(private readonly getProvider: (chainId: number) => Provider) {}

  private getYLDRLeverageAutomationsService(
    chainId: number,
    leverageAutomateContractAddress: string,
  ) {
    const provider = this.getProvider(chainId);
    return new YLDRLeverageAutomations({
      leverageAutomateContractAddress: leverageAutomateContractAddress,
      provider,
    });
  }

  async getConfiguredAutomations(
    chainId: number,
    leverageAutomateContractAddress: string,
    positions: tEthereumAddress[],
  ): Promise<[
    ScheduledRebalanceStructOutput[],
    ScheduledDeleverageStructOutput[],
    ScheduledCompoundStructOutput[]
  ] & {
    rebalances: ScheduledRebalanceStructOutput[],
    deleverages: ScheduledDeleverageStructOutput[],
    compounds: ScheduledCompoundStructOutput[]
  }> {
    const YLDRLeverageAutomations = this.getYLDRLeverageAutomationsService(chainId, leverageAutomateContractAddress);
    return YLDRLeverageAutomations.getConfiguredAutomations(positions);
  }
}
