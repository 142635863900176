export const POOL_ADDRESSES_PROVIDER = '0x488402D92f32eEdA5cf61521ADf7f8e8f1DcaC20';
export const POOL = '0x8183D4e0561cBdc6acC0Bdb963c352606A2Fa76F';
export const UI_POOL_DATA_PROVIDER = '0x2464dA5c26651cdceD9f6afa9afdc79B5f6413AD';
export const WALLET_BALANCE_PROVIDER = '0xa033c2055315463bDF65d7285FbdbBd692E8290E';
export const WETH_GATEWAY = '0xeCD2F9ddd2c99163c11bBc9c4A7e620ef3b5fb68';
export const FALLBACK_FLASHLOAN_PROVIDER = '0x159Db1d5C251DD8f84c8abcd037FCdbf3b2B4D73';
export const PREFERRED_FLASHLOAN_PROVIDERS = [
  '0x691aBe9a9750378cB125766AfDA6e26018b673F5',
  '0x28B40dBC95C07E04703795Fd5602e633E4aad6B7',
  '0x6A88a796047aB836e270b50219a855607e585244',
  '0xe3816dAff2FF8F6B3fA8e04A2fE1685E5c785220',
];
export const ASSET_CONVERTER = '0xc294CeD4A4856e8fCda3567A6A71e6dE393bfFeD';
