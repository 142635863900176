export enum QueryKeys {
  POWERS = 'POWERS',
  VOTE_ON_PROPOSAL = 'VOTE_ON_PROPOSAL',
  VOTING_POWER_AT = 'VOTING_POWER_AT',
  GOVERNANCE_TOKENS = 'GOVERNANCE_TOKENS',
  TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
  POOL_TOKENS = 'POOL_TOKENS',
  GENERAL_STAKE_UI_DATA = 'GENERAL_STAKE_UI_DATA',
  POOL_RESERVES_DATA_HUMANIZED = 'MARKET_RESERVES_DATA_HUMANIZED',
  USER_STAKE_UI_DATA = 'USER_STAKE_UI_DATA',
  GAS_PRICES = 'GAS_PRICES',
  POSITIONS = 'POSITIONS',
  POSITIONS_DATA = 'POSITIONS_DATA',
  SUPPLIED_POSITIONS_DATA = 'SUPPLIED_POSITIONS_DATA',
  LEVERAGED_POSITIONS = 'LEVERAGED_POSITIONS',
  LEVERAGED_POSITIONS_DATA = 'LEVERAGED_POSITIONS_DATA',
  LEVERAGED_ERC20_POSITIONS = 'LEVERAGED_ERC20_POSITIONS',
  LEVERAGED_ERC20_POSITIONS_DATA = 'LEVERAGED_ERC20_POSITIONS_DATA',
  LP_POOL = 'LP_POOL',
  ALM_POOL = 'ALM_POOL',
  STEER_POOL_APR = 'STEER_POOL_APR',
  GAMMA_POOL_APR = 'GAMMA_POOL_APR',
  ALM_POOL_DATA = 'ALM_POOL_DATA',
  FLASH_LENDER_FEE = 'FLASH_LENDER_FEE',
  GLOBAL_REVENUE_FEES = 'GLOBAL_REVENUE_FEES',
  DISPLAYED_PAIRS = 'DISPLAYED_PAIRS',
  ALM_MARKETS = 'ALM_MARKETS',
  MARKETS = 'MARKETS',
  POOL_TICK_SPACING = 'POOL_TICK_SPACING',
  POOL_GLOBAL_STATE = 'POOL_GLOBAL_STATE',
  YLDR_LEVERAGE_AUTOMATIONS = 'YLDR_LEVERAGE_AUTOMATIONS',
}

export const POLLING_INTERVAL = 60000;
export const FAST_POLLING_INTERVAL = 5000;
