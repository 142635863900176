import { formatReservesAndIncentives, formatUserSummaryAndIncentives } from '@yldr/math-utils';
import { fetchIconSymbolAndName } from 'src/ui-config/reservePatches';
import { CustomMarket, marketsData } from 'src/utils/marketsAndNetworksConfig';

import { PoolReserve } from './poolSlice';
import { RootStore } from './root';

export const reserveSortFn = (
  a: { totalLiquidityUSD: string },
  b: { totalLiquidityUSD: string }
) => {
  const numA = parseFloat(a.totalLiquidityUSD);
  const numB = parseFloat(b.totalLiquidityUSD);

  return numB > numA ? 1 : -1;
};

export const selectCurrentChainIdMarkets = (state: RootStore) => {
  const marketNames = Object.keys(marketsData);
  return Object.values(marketsData)
    .map((marketData, index) => ({
      ...marketData,
      marketName: marketNames[index] as CustomMarket,
    }))
    .filter(
      (marketData) =>
        marketData.chainId == state.currentChainId &&
        state.currentNetworkConfig.isFork == marketData.isFork
    );
};

export const selectCurrentChainIdV3MarketData = (state: RootStore) => {
  const currentChainIdMarkets = selectCurrentChainIdMarkets(state);
  const marketData = currentChainIdMarkets.filter((marketData) => marketData.v3);
  return marketData[0];
};

export const selectCurrentUserLendingPoolData = (state: RootStore) => {
  const marketAddressProvider = state.currentMarketData
    ? state.currentMarketData.addresses.LENDING_POOL_ADDRESS_PROVIDER
    : undefined;
  const currentChainId = state.currentChainId;
  if (marketAddressProvider && currentChainId) {
    return state.data.get(state.currentChainId)?.get(marketAddressProvider);
  }
  return undefined;
};

export const selectFormatUserReserves = (reserve?: PoolReserve) => {
  return reserve?.userReserves || [];
};

export const selectFormatUserERC1155Reserves = (reserve?: PoolReserve) => {
  return reserve?.userERC1155Reserves || [];
}

export const selectCurrentUserReserves = (state: RootStore) => {
  return selectFormatUserReserves(selectCurrentUserLendingPoolData(state));
};

export const selectCurrentUserERC1155Reserves = (state: RootStore) => {
  return selectFormatUserERC1155Reserves(selectCurrentUserLendingPoolData(state));
}

export const selectFormatReserves = (reserve?: PoolReserve) => {
  return reserve?.reserves || [];
};

export const selectCurrentReserves = (state: RootStore) => {
  return selectFormatReserves(selectCurrentUserLendingPoolData(state));
};

export const selectFormatBaseCurrencyData = (reserve?: PoolReserve) => {
  return (
    reserve?.baseCurrencyData || {
      marketReferenceCurrencyDecimals: 0,
      marketReferenceCurrencyPriceInUsd: '0',
      networkBaseTokenPriceInUsd: '0',
      networkBaseTokenPriceDecimals: 0,
    }
  );
};

export const selectCurrentBaseCurrencyData = (state: RootStore) => {
  return selectFormatBaseCurrencyData(selectCurrentUserLendingPoolData(state));
};

export const selectFormattedReserves = (state: RootStore, currentTimestamp: number) => {
  const reserves = selectCurrentReserves(state);
  const baseCurrencyData = selectCurrentBaseCurrencyData(state);
  const currentNetworkConfig = state.currentNetworkConfig;

  const formattedPoolReserves = formatReservesAndIncentives({
    reserves,
    currentTimestamp,
    marketReferenceCurrencyDecimals: baseCurrencyData.marketReferenceCurrencyDecimals,
    marketReferencePriceInUsd: baseCurrencyData.marketReferenceCurrencyPriceInUsd,
    reserveIncentives: state.reserveIncentiveData || [],
  })
    .map((r) => ({
      ...r,
      ...fetchIconSymbolAndName(r),
      isWrappedBaseAsset:
        r.symbol.toLowerCase() === currentNetworkConfig.wrappedBaseAssetSymbol?.toLowerCase(),
    }))
    .sort(reserveSortFn);

  return formattedPoolReserves;
};

export const selectUserSummaryAndIncentives = (state: RootStore, currentTimestamp: number) => {
  const baseCurrencyData = selectCurrentBaseCurrencyData(state);
  const userReserves = selectCurrentUserReserves(state);
  const userERC1155Reserves = selectCurrentUserERC1155Reserves(state);
  const formattedPoolReserves = selectFormattedReserves(state, currentTimestamp);
  const reserveIncentiveData = state.reserveIncentiveData;
  const userIncentiveData = state.userIncentiveData;

  // TODO: why <any>
  return formatUserSummaryAndIncentives({
    currentTimestamp,
    marketReferencePriceInUsd: baseCurrencyData.marketReferenceCurrencyPriceInUsd,
    marketReferenceCurrencyDecimals: baseCurrencyData.marketReferenceCurrencyDecimals,
    userReserves,
    userERC1155Reserves,
    formattedReserves: formattedPoolReserves,
    reserveIncentives: reserveIncentiveData || [],
    userIncentives: userIncentiveData || [],
  });
};

export const selectUserNonEmptySummaryAndIncentive = (state: RootStore, currentTimestamp: number) => {
  const user = selectUserSummaryAndIncentives(state, currentTimestamp);
  const userReservesData = user.userReservesData.filter(
    (userReserve) => userReserve.underlyingBalance !== '0'
  );
  return {
    ...user,
    userReservesData,
  };
};

export const selectNonEmptyUserBorrowPositions = (state: RootStore, currentTimestamp: number) => {
  const user = selectUserSummaryAndIncentives(state, currentTimestamp);
  const borrowedPositions = user.userReservesData.filter(
    (reserve) => reserve.variableBorrows != '0'
  );
  return borrowedPositions;
};
