import React, { createContext, useContext, useState } from 'react';

import { IBackgroundContext } from './types';

export const BackgroundContext = createContext<IBackgroundContext>({} as IBackgroundContext);

export const BackgroundProvider = ({ children }: { children?: React.ReactNode }) => {
    const [background, setBackground] = useState<IBackgroundContext['background']>({
        segments: [],
        width: 0,
        bgOffset: 0,
    });
    return (
        <BackgroundContext.Provider value={{ background, setBackground }}>
            {children}
        </BackgroundContext.Provider>
    );
};

export const useBackground = () => {
    const ctx = useContext(BackgroundContext);
    if (!ctx) throw new Error('No context found. Check <BackgroundContext.Provider/> to be present');
    return ctx;
};
