import { createMulticall } from '@uniswap/redux-multicall';

import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { useBlockNumber } from './useBlockNumber';
import { SkipFirstTwoParams } from './types';

export const multicall = createMulticall({ reducerPath: 'multicall' });

export const useMultipleContractSingleData = (
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useMultipleContractSingleData>
) => {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useMultipleContractSingleData(chainId, latestBlock, ...args)
};

export function useSingleContractMultipleData(
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleContractMultipleData>
) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useSingleContractMultipleData(chainId, latestBlock, ...args)
}

export function useSingleCallResult(...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleCallResult>) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useSingleCallResult(chainId, latestBlock, ...args)
}

function useCallContext() {
  const { chainId } = useWeb3Context();
  const latestBlock = useBlockNumber();
  return { chainId, latestBlock };
}
