import { Provider } from '@ethersproject/providers';
import { UniswapV3LeverageDataProvider } from '@yldr/contract-helpers';
import { LeveragedUniswapV3PositionData } from 'packages/contract-helpers/dist/esm/uniswapv3-leverage-data-provider/types';
import { BigNumber } from 'ethers';

export class UiLeveragePositionService {
  constructor(private readonly getProvider: (chainId: number) => Provider) {}

  private getUiPositionService(
    chainId: number,
    leverageDataProviderAddress: string,
  ) {
    const provider = this.getProvider(chainId);
    return new UniswapV3LeverageDataProvider({
      uniswapV3LeverageDataProviderAddress: leverageDataProviderAddress,
      provider,
    });
  }

  async getPositionsData(
    chainId: number,
    leverageDataProviderAddress: string,
    addresses: string[],
  ): Promise<LeveragedUniswapV3PositionData[]> {
    const uiPoolDataProvider = this.getUiPositionService(chainId, leverageDataProviderAddress);
    return uiPoolDataProvider.getPositionsData({ addresses });
  }

  async getGlobalRevenueFee(
    chainId: number,
    leverageDataProviderAddress: string,
  ): Promise<BigNumber> {
    const uiPoolDataProvider = this.getUiPositionService(chainId, leverageDataProviderAddress);
    return uiPoolDataProvider.getGlobalRevenueFee();
  }
}
