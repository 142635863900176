export const POOL_ADDRESSES_PROVIDER = '0x66d2eaD9cbE6754985a9Be7B829502228Ef8b49B';
export const POOL = '0x54aD657851b6Ae95bA3380704996CAAd4b7751A3';
export const UI_POOL_DATA_PROVIDER = '0x775f2616557824bbcf2ea619cA2BacaBd930F2BD';
export const WALLET_BALANCE_PROVIDER = '0x348f1512514C27c18275d61312e2387Bd851aDa2';
export const WETH_GATEWAY = '0x9Bb036E6824683163126B8BA0E1413Daf8263D0D';
export const FALLBACK_FLASHLOAN_PROVIDER = '0x941e768A26C5cCa02F1aee113FB94315Af4FD412';
export const PREFERRED_FLASHLOAN_PROVIDERS = [
  '0x0B6b6AbBc1aB8b5CE10DC197B663DeC56c81e714',
  '0x58011b287553E8053baA91587F6A516fa6614883',
  '0x0DCcc7E957a255132F396b74DD1e2E453F8bBa0e',
  '0xa24692F9460Fa5bb3d9Bfa4D80543205E7eaE54d',
  '0x6bF5866100b0CCCaD1021019e5Ca6d8487E60D94',
  '0x3098d11B4F9e9ebc97972132cD4fCB024Ef79150',
  '0x3477Ff51E9115B41ce1Bb352902e94c360f13e61',
  '0x16b6b8339D98281068F81b88a6Ec8A398D4afd8B',
  '0xe11e7966f2eAa0B3976C9b290D1c915a1c91009B',
  '0x7c6d91440F7bB6CFEa4802cD083A88FA9693FF9a',
];
export const ASSET_CONVERTER = '0xAA81Ea3AbB14fD0c9b3bE83e7a2fC2f8c7D87707';
export const CHAIN_ID = 42161;
