import { Provider } from '@ethersproject/providers';
import { IERC3156FlashLenderService } from '@yldr/contract-helpers/dist/esm/erc3156-lender';
import { BigNumber } from 'ethers';
import { tEthereumAddress } from '@yldr/contract-helpers';
import { MarketDataType } from '../ui-config/marketsConfig';

export class UiFlashLenderService {
  constructor(private readonly getProvider: (chainId: number) => Provider) {}

  private getFlashLenderService(marketData: MarketDataType) {
    const provider = this.getProvider(marketData.chainId);
    return new IERC3156FlashLenderService(provider);
  }

  async getFlashLenderFee(
    marketData: MarketDataType,
    token: tEthereumAddress = '',
    amount: string = '',
  ): Promise<BigNumber> {
    const lender = marketData.addresses.FALLBACK_FLASHLOAN_PROVIDER;
    const flashLenderService = this.getFlashLenderService(marketData);
    return flashLenderService.getFee(lender, token, amount);
  }
}
