import { ChainId, UniswapV3DataProvider } from '@yldr/contract-helpers';
import { Provider } from '@ethersproject/providers';
import { UniswapV3PositionData } from '@yldr/contract-helpers';

export class UiPositionService {
  constructor(private readonly getProvider: (chainId: number) => Provider) {}

  private getUiPositionService(chainId: ChainId, dataProviderAddress: string) {
    const provider = this.getProvider(chainId);
    return new UniswapV3DataProvider({
      uniswapV3DataProviderAddress: dataProviderAddress,
      provider,
    });
  }

  async getPositionsData(
    chainId: ChainId,
    dataProviderAddress: string,
    tokenIds: any[],
  ): Promise<UniswapV3PositionData[]> {
    const uiPoolDataProvider = this.getUiPositionService(chainId, dataProviderAddress);
    return uiPoolDataProvider.getPositionsData({ tokenIds });
  }
}
