import { Provider } from '@ethersproject/providers';
import { ALMLeverageDataProvider } from '@yldr/contract-helpers';
import { LeveragedAmlPositionData } from '@yldr/contract-helpers/dist/esm/ALMLeverageDataProvider/types';

export class UiAlmLeveragePositionService {
  constructor(private readonly getProvider: (chainId: number) => Provider) {}

  private getUiAlmPositionService(
    chainId: number,
    leverageDataProviderAddress: string,
  ) {
    const provider = this.getProvider(chainId);
    return new ALMLeverageDataProvider({
      leverageDataProviderAddress,
      provider,
    });
  }

  async getPositionsData(
    chainId: number,
    leverageDataProviderAddress: string,
    positionAddresses: string[],
  ): Promise<LeveragedAmlPositionData[]> {
    const uiPoolDataProvider = this.getUiAlmPositionService(chainId, leverageDataProviderAddress);
    return uiPoolDataProvider.getPositionsData({ positionAddresses });
  }
}
