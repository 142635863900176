import { ChainId } from '@yldr/contract-helpers';
import { EMarketName } from './marketsConfig';

export type ExplorerLinkBuilderProps = {
  tx?: string;
  address?: string;
};

export type ExplorerLinkBuilderConfig = {
  baseUrl: string;
  addressPrefix?: string;
  txPrefix?: string;
};

export type NetworkConfig = {
  name: string;
  displayName?: string;
  privateJsonRPCUrl?: string; // private rpc will be used for rpc queries inside the client. normally has private api key and better rate
  privateJsonRPCWSUrl?: string;
  publicJsonRPCUrl: readonly string[]; // public rpc used if not private found, and used to add specific network to wallets if user don't have them. Normally with slow rates
  publicJsonRPCWSUrl?: string;
  // protocolDataUrl: string;
  // https://github.com/aave/aave-api
  yldrYpiUrl: string;
  ratesHistoryApiUrl?: string;
  // cachingServerUrl?: string;
  // cachingWSServerUrl?: string;
  baseUniswapAdapter?: string;
  /**
   * When this is set withdrawals will automatically be unwrapped
   */
  wrappedBaseAssetSymbol: string;
  baseAssetSymbol: string;
  // needed for configuring the chain on metemask when it doesn't exist yet
  baseAssetDecimals: number;
  // usdMarket?: boolean;
  // function returning a link to etherscan et al
  explorerLink: string;
  explorerLinkBuilder: (props: ExplorerLinkBuilderProps) => string;
  // set this to show faucets and similar
  isTestnet?: boolean;
  // get's automatically populated on fork networks
  isFork?: boolean;
  networkLogoPath: string;
  // contains the forked off chainId
  underlyingChainId?: number;
  maxDataBlockAge: number;
  compoundGasCost: number;
  bridge?: {
    icon: string;
    name: string;
    url: string;
  };
};

export type BaseNetworkConfig = Omit<NetworkConfig, 'explorerLinkBuilder'>;

export const networkConfigs: Record<string, BaseNetworkConfig> = {
  [ChainId.mainnet]: {
    name: 'Ethereum',
    // privateJsonRPCUrl: 'https://eth-mainnet.gateway.pokt.network/v1/lb/62b3314e123e6f00397f19ca',
    publicJsonRPCUrl: [
      'https://ethereum-rpc.publicnode.com',
      'https://rpc.flashbots.net',
      'https://eth-mainnet.public.blastapi.io',
      'https://cloudflare-eth.com/v1/mainnet',
      // 'https://rpc.ankr.com/eth',
    ],
    yldrYpiUrl: process.env.NEXT_PUBLIC_API as string,
    publicJsonRPCWSUrl: 'wss://eth-mainnet.alchemyapi.io/v2/demo',
    // cachingServerUrl: 'https://cache-api-1.aave.com/graphql',
    // cachingWSServerUrl: 'wss://cache-api-1.aave.com/graphql',
    // protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    baseUniswapAdapter: '0xc3efa200a60883a96ffe3d5b492b121d6e9a1f3f',
    baseAssetSymbol: 'ETH',
    wrappedBaseAssetSymbol: 'WETH',
    baseAssetDecimals: 18,
    explorerLink: 'https://etherscan.io',
    ratesHistoryApiUrl: 'https://aave-api-v2.aave.com/data/rates-history',
    networkLogoPath: '/icons/networks/ethereum.svg',
    maxDataBlockAge: 50,
    compoundGasCost: 250,
  },
  [ChainId.arbitrum_one]: {
    name: 'Arbitrum',
    publicJsonRPCUrl: [
      'https://arb1.arbitrum.io/rpc',
      'https://1rpc.io/arb',
      'https://arbitrum-one-rpc.publicnode.com',
      // 'https://rpc.ankr.com/arbitrum',
    ],
    yldrYpiUrl: process.env.NEXT_PUBLIC_API as string,
    publicJsonRPCWSUrl: 'wss://arb1.arbitrum.io/rpc',
    // protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAssetSymbol: 'ETH',
    wrappedBaseAssetSymbol: 'WETH',
    baseAssetDecimals: 18,
    explorerLink: 'https://arbiscan.io',
    // usdMarket: true,
    networkLogoPath: '/icons/networks/arbitrum.svg',
    maxDataBlockAge: 500,
    compoundGasCost: 0.30,
    bridge: {
      icon: '/icons/bridge/arbitrum.svg',
      name: 'Arbitrum Bridge',
      url: 'https://bridge.arbitrum.io',
    },
    ratesHistoryApiUrl: 'https://aave-api-v2.aave.com/data/rates-history',
  },
  [ChainId.polygon]: {
    name: 'Polygon',
    publicJsonRPCUrl: [
      'https://polygon-rpc.com',
      'https://1rpc.io/matic',
      'https://polygon-bor-rpc.publicnode.com',
      // 'https://rpc.ankr.com/polygon',
      // 'https://polygon.llamarpc.com',
    ],
    yldrYpiUrl: process.env.NEXT_PUBLIC_API as string,
    publicJsonRPCWSUrl: 'wss://polygon-bor-rpc.publicnode.com',
    // protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAssetSymbol: 'MATIC',
    wrappedBaseAssetSymbol: 'WMATIC',
    baseAssetDecimals: 18,
    explorerLink: 'https://polygonscan.com',
    // usdMarket: true,
    networkLogoPath: '/icons/networks/polygon.svg',
    maxDataBlockAge: 100,
    compoundGasCost: 0.15,
    bridge: {
      icon: '/icons/bridge/polygon.svg',
      name: 'Polygon Bridge',
      url: 'https://portal.polygon.technology/',
    },
    ratesHistoryApiUrl: 'https://aave-api-v2.aave.com/data/rates-history',
  },
  [ChainId.base]: {
    name: 'Base',
    publicJsonRPCUrl: [
      'https://mainnet.base.org',
      'https://1rpc.io/base',
      'https://base.drpc.org',
    ],
    yldrYpiUrl: process.env.NEXT_PUBLIC_API as string,
    publicJsonRPCWSUrl: 'wss://base-rpc.publicnode.com',
    // protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAssetSymbol: 'ETH',
    wrappedBaseAssetSymbol: 'WETH',
    baseAssetDecimals: 18,
    explorerLink: 'https://basescan.org',
    // usdMarket: true,
    networkLogoPath: '/icons/networks/base.svg',
    maxDataBlockAge: 100,
    compoundGasCost: 0.05,
    bridge: {
      icon: '/icons/bridge/base.svg',
      name: 'Base Bridge',
      url: 'https://bridge.base.org',
    },
    ratesHistoryApiUrl: 'https://aave-api-v2.aave.com/data/rates-history',
  },
  // [ChainId.goerli]: {
  //   name: 'Ethereum Görli',
  //   publicJsonRPCUrl: [
  //     'https://eth-goerli.public.blastapi.io',
  //     'https://rpc.ankr.com/eth_goerli',
  //     // 'https://goerli.prylabs.net',
  //   ],
  //   yldrYpiUrl: 'https://yldr.dev/api',
  //   publicJsonRPCWSUrl: 'wss://eth-goerli.public.blastapi.io',
  //   // protocolDataUrl: '',
  //   baseUniswapAdapter: '0x0',
  //   baseAssetSymbol: 'ETH',
  //   wrappedBaseAssetSymbol: 'WETH',
  //   baseAssetDecimals: 18,
  //   explorerLink: 'https://goerli.etherscan.io',
  //   // usdMarket: true,
  //   isTestnet: true,
  //   networkLogoPath: '/icons/networks/ethereum.svg',
  //   maxDataBlockAge: 50,
  // },
  // [ChainId.arbitrum_goerli]: {
  //   name: 'Arbitrum Görli',
  //   publicJsonRPCUrl: [
  //     'https://goerli-rollup.arbitrum.io/rpc',
  //     'https://arb-goerli.g.alchemy.com/v2/demo',
  //   ],
  //   yldrYpiUrl: 'https://yldr.dev/api',
  //   publicJsonRPCWSUrl: 'wss://goerli-rollup.arbitrum.io/rpc',
  //   baseUniswapAdapter: '0x0',
  //   baseAssetSymbol: 'ETH',
  //   wrappedBaseAssetSymbol: 'WETH',
  //   baseAssetDecimals: 18,
  //   explorerLink: 'https://goerli.arbiscan.io',
  //   // usdMarket: true,
  //   isTestnet: true,
  //   networkLogoPath: '/icons/networks/arbitrum.svg',
  //   maxDataBlockAge: 500,
  //   bridge: {
  //     icon: '/icons/bridge/arbitrum.svg',
  //     name: 'Arbitrum Bridge',
  //     url: 'https://bridge.arbitrum.io',
  //   },
  // },
} as const;

export const CHAIN_SUBGRAPH_URL: Record<number, Partial<Record<EMarketName, string>>> = {
  [ChainId.mainnet]: {
    [EMarketName.Uniswap]: 'https://gateway-arbitrum.network.thegraph.com/api/2c95fe04164b241b66fcb3ee71abc33b/subgraphs/id/5zvR82QoaXYFyDEKLZ9t6v9adgnptxYpKpSbxtgVENFV',
  },
  [ChainId.arbitrum_one]: {
    [EMarketName.Uniswap]: 'https://gateway-arbitrum.network.thegraph.com/api/2c95fe04164b241b66fcb3ee71abc33b/subgraphs/id/FbCGRftH4a3yZugY7TnbYgPJVEv2LvMT6oF1fxPe9aJM',
    [EMarketName.Camelot]: 'https://gateway.thegraph.com/api/2c95fe04164b241b66fcb3ee71abc33b/deployments/id/QmVPCT62C6b2m2D3AnfEF1hJhhmYEenuQtUDLMj1vEBt4m',
    // [EMarketName.Camelot]: 'https://api.thegraph.com/subgraphs/name/camelotlabs/camelot-amm-v3-2',
  },
  [ChainId.polygon]: {
    [EMarketName.Uniswap]: 'https://gateway-arbitrum.network.thegraph.com/api/2c95fe04164b241b66fcb3ee71abc33b/subgraphs/id/3hCPRGf4z88VC5rsBKU5AA9FBBq5nF3jbKJG7VZCbhjm',
    [EMarketName.Quickswap]: 'https://gateway-arbitrum.network.thegraph.com/api/2c95fe04164b241b66fcb3ee71abc33b/subgraphs/id/CCFSaj7uS128wazXMdxdnbGA3YQnND9yBdHjPtvH7Bc7',
    // [EMarketName.Quickswap]: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap-v3',
  },
  [ChainId.base]: {
    [EMarketName.Uniswap]: 'https://gateway-arbitrum.network.thegraph.com/api/2c95fe04164b241b66fcb3ee71abc33b/subgraphs/id/43Hwfi3dJSoGpyas9VwNoDAv55yjgGrPpNSmbQZArzMG',
  }
}

export const DEFAULT_SLIPPAGE_OPTIONS = ['0.5', '1'];

export const TOKEN_WHITELIST: Partial<Record<ChainId, string[]>> = {
  [ChainId.arbitrum_one]: [
    '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
    '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    '0x912ce59144191c1204e64559fe8253a0e49e6548',
    '0x5979d7b546e38e414f7e9822514be443a4800529',
    '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  ],
  [ChainId.mainnet]: [
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
    '0xbe9895146f7af43049ca1c1ae358b0541ea49704',
    '0xae78736cd615f374d3085123a210448e74fc6393',
    '0xdac17f958d2ee523a2206206994597c13d831ec7',
  ],
  [ChainId.polygon]: [
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  ],
  [ChainId.base]: [
    '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
    '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
    '0x4200000000000000000000000000000000000006',
    '0xc1cba3fcea344f92d9239c08c0568f6f2f0ee452',
  ],
}

export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000001';
